body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
p {
    margin: 0;
}

.m-0 {
    margin: 0;
}
.p-0 {
    padding: 0;
}
.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.uppercase {
    text-transform: uppercase;
}
.d-flex{
    display: flex;
}
.icon-btn{
    height: 100%;
    padding: 6px;
    background: unset;
    box-shadow: unset;
    border: unset;
}
button.btn{
    height: 40px;
    border-radius: 6px;
    border: none;
    box-shadow: none;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    background: linear-gradient(to right, rgb(238, 9, 121), rgb(255, 106, 0));
    font-weight: 600;
    letter-spacing: 0.6px;
    display: flex;
    align-items: center;
    cursor: pointer;
}