.App {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
}
.mobile_frame {
    /* max-width: 414px;
    width: 100%; */
    height: 100%;
    margin: auto;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: start;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}
.mobile_frame__header {
    height: 60px;
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    /* -webkit-backdrop-filter: blur(5px); */
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.mobile_frame__body {
    height: calc(100% - 210px);
    width: 100%;
    padding: 20px;
}
.mobile_frame__body .game_cont {
    padding: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    /* backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px); */
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.mobile_frame__footer {
    height: 80px;
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
}
.mobile_frame__footer .left {
    border-right: 1px solid #fff;
    width: 50%;
    display: flex;
}
.mobile_frame__footer .right {
    width: 50%;
    display: flex;
}
.mobile_frame__footer .left .footer_icon_cont {
    background-color: #ffcc00;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.mobile_frame__footer .right .footer_icon_cont {
    background-color: #051548;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    order: 1;
}
.mobile_frame__footer .left .player,
.mobile_frame__footer .right .player {
    background-color: #fff;
    width: 100%;
    color: #000;
    padding: 10px 10px 10px 14px;
}

.mobile_frame__footer .left .player .player_name {
    color: #f37335;
}
.mobile_frame__footer .right .player .player_name {
    color: #051548;
}
.mobile_frame__footer .left .cross-icon {
    font-size: 10px;
    width: 40px;
    height: 40px;
    margin: auto 0;
}
.mobile_frame__footer .left .cross-icon:before,
.mobile_frame__footer .left .cross-icon:after {
    width: 7px;
    background-color: #fff;
    filter: none;
}

.mobile_frame__footer .right .circle-icon {
    font-size: 10px;
    width: 40px;
    height: 40px;
    border: 7px solid #fff;
    filter: none;
}

.game_cont .row {
    display: flex;
}
.game_cont .square {
    width: 33.33%;
    aspect-ratio: 1;
    background-color: #0000004d;
    margin: 10px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cross-icon {
    width: 70px;
    height: 70px;
    background-color: transparent;
    position: relative;
}

.cross-icon:before,
.cross-icon:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 10px;
    background-color: #ffcc00;
    filter: drop-shadow(0 0 5px #f37335);
    top: 0;
    left: 43%;
    transform-origin: center;
}

.cross-icon:before {
    transform: rotate(45deg);
}

.cross-icon:after {
    transform: rotate(-45deg);
}

.mobile_frame__header__title .title {
    margin-left: 20px;
}
.mobile_frame__header__title .title h4 {
    text-transform: uppercase;
    margin: 0;
    color: whitesmoke;
}
.mobile_frame__header__title .title h2 {
    margin: 0;
    text-transform: capitalize;
}

.circle-icon {
    width: 60%;
    aspect-ratio: 1;
    border: 10px solid #ffffff;
    border-radius: 50%;
    box-sizing: border-box;
    /* filter: drop-shadow(0 0 5px #3498db); */
}

.mobile_frame__header__title {
    height: 100%;
    display: flex;
    align-items: center;
}
.mobile_frame__header__title .icon {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.restart_button {
    margin-left: auto;
    height: 80%;
    margin-right: 10px;
    border-radius: 6px;
    background-color: #00000059;
}
.restart_button .icon-btn {
    height: 100%;
    padding: 10px;
}
.play_again {
    margin-left: auto;
    width: fit-content;
    margin-top: 10px;
}
.play_again img {
    width: 20px;
    padding-left: 10px;
    font-weight: bold;
}


.installation_popup{
    position: absolute;
    bottom: 37px;
    left: 10px;
    right: 10px;
    height: 80px;
    border-radius: 8px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    color: #000;
}
.installation_popup .app_icon{
    background-color: hsl(0deg 0% 0% / 15%);
    padding: 10px;
    border-radius: 8px;
}
.installation_popup .btn{
    margin: auto 0 auto auto;
}
.installation_popup .msg{
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.player_name{
    all: unset;
    font-size: 20px;
    font-weight: bold;
    width: inherit;
}